import SDK3DVerse_ExtensionInterface from 'ExtensionInterface';
import TransformControls from './TransformControls';
import RectTool from './RectTool';
import * as THREE from 'three';

//------------------------------------------------------------------------------
/**
 * Add a widget to translate, scale or rotate selected entities
 * on the active viewport.
 *
 *
 * @module SDK3DVerse_Gizmos_Ext
 * @category Extensions
 */

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class SDK3DVerse_Gizmos_Ext extends SDK3DVerse_ExtensionInterface
{
    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(sdk)
    {
        super(sdk, "Gizmos");

        this.canvas                 = sdk.streamerConfig.display.canvas;
        this.context                = this.canvas.getContext("2d");
        this.commitChanges          = false;
        this.transformableEntities  = [];

        this.currentSpace           = 'world';
        this.currentControlMode     = 'translate';
        this.isVisible              = false;
        this.enabled                = true;
        this.defaultAABB            = { min : [-1, -1, -1], max : [1, 1, 1] };

        this.renderer           = new THREE.WebGLRenderer({ alpha: true, antialias : true });
        this.renderer.autoClear = false;
        this.renderer.setPixelRatio(1);
        this.renderer.setClearColor(0xffffff, 0);
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
        this.overlayCanvas = this.renderer.domElement;

        this.camera     = new THREE.PerspectiveCamera();
        this.camera.matrixAutoUpdate = false;

        this.scene      = new THREE.Scene();
        this.mesh       = new THREE.Object3D();

        this.controller = this.createController();
        this.rectTool   = new RectTool(this.camera, this.canvas, this.sdk);
        this.rectTool.onPositionUpdated = this.onGizmoPositionUpdated;
        this.rectTool.onScaleUpdated    = this.onGizmoScaleUpdated;

        this.rectTool.onTransformStarted   = () => this.sdk.notifier.off('onEntitiesUpdated', this.onEntitiesUpdated);
        this.rectTool.onTransformEnded     = () =>
        {
            this.sdk.notifier.on('onEntitiesUpdated', this.onEntitiesUpdated);
            this.onGizmoTransformEnded();
        };

        this.updateClientRect();

        this.scene.add(this.controller);
        this.scene.add(this.mesh);

        this.onVisibilityChanged = (isVisible) => {};
    }

    //--------------------------------------------------------------------------
    createController()
    {
        const controller = new TransformControls(this.camera, this.canvas);
        controller.setSpace(this.currentSpace);
        controller.setMode(this.currentControlMode);

        controller.onTransformStarted   = () => this.sdk.notifier.off('onEntitiesUpdated', this.onEntitiesUpdated);
        controller.onTransformEnded     = () =>
        {
            this.sdk.notifier.on('onEntitiesUpdated', this.onEntitiesUpdated);
            this.onGizmoTransformEnded();
        };

        controller.duplicateEntity      = async () =>
        {
            let lastRotationAxis = 0, lastRotationAngle = 0;

            controller.UpdateSelectedEntityPosition = () => {};
            controller.UpdateSelectedEntityRotation = (rotationAxis, rotationAngle) =>
            {
                lastRotationAxis    = rotationAxis;
                lastRotationAngle   = rotationAngle;
            };
            controller.UpdateSelectedEntityScale    = () => {};

            const createdEntities       = await this.duplicateEntities(this.transformableEntities);
            this.transformableEntities  = createdEntities;

            this.onGizmoPositionUpdated();
            this.onGizmoOrientationUpdated(lastRotationAxis, lastRotationAngle);
            this.onGizmoScaleUpdated();

            controller.UpdateSelectedEntityPosition = this.onGizmoPositionUpdated;
            controller.UpdateSelectedEntityRotation = this.onGizmoOrientationUpdated;
            controller.UpdateSelectedEntityScale    = this.onGizmoScaleUpdated;

            SDK3DVerse.engineAPI.selectEntities(createdEntities);
        };

        controller.UpdateSelectedEntityPosition = this.onGizmoPositionUpdated;
        controller.UpdateSelectedEntityRotation = this.onGizmoOrientationUpdated;
        controller.UpdateSelectedEntityScale    = this.onGizmoScaleUpdated;
        controller.enabled                      = false;

        return controller;
    }

    //--------------------------------------------------------------------------
    async onInit()
    {
        this.sdk.notifier.on('onFramePostRender', this.onFrameRendered);
        this.sdk.notifier.on('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.on('onEntitySelectionChanged', this.onEntitySelectionChanged);
        this.sdk.notifier.on('onEntitiesUpdated', this.onEntitiesUpdated);
        this.sdk.notifier.on('onEntityReparent', this.onEntityReparent);

        this.sdk.notifier.on('onViewportSelected', this.onViewportSelected);
        this.sdk.notifier.on('onViewportsUpdated', this.updateClientRect);
        this.sdk.notifier.on('onCanvasResized', this.updateClientRect);
        this.sdk.notifier.on('onDisplayReady', this.updateDisplay);
    }

    //--------------------------------------------------------------------------
    dispose()
    {
        this.sdk.notifier.off('onFramePostRender', this.onFrameRendered);
        this.sdk.notifier.off('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.off('onEntitySelectionChanged', this.onEntitySelectionChanged);
        this.sdk.notifier.off('onEntitiesUpdated', this.onEntitiesUpdated);
        this.sdk.notifier.off('onEntityReparent', this.onEntityReparent);

        this.sdk.notifier.off('onViewportSelected', this.onViewportSelected);
        this.sdk.notifier.off('onViewportsUpdated', this.updateClientRect);
        this.sdk.notifier.off('onCanvasResized', this.updateClientRect);
        this.sdk.notifier.off('onDisplayReady', this.updateDisplay);

        this.renderer.dispose();
        this.renderer.forceContextLoss();
        this.renderer.context = null;
        this.renderer.domElement = null;

        this.releaseController();

        this.controller.dispose();
    }

    //--------------------------------------------------------------------------
    updateDisplay = () =>
    {
        this.canvas     = this.sdk.streamerConfig.display.canvas;
        this.context    = this.canvas.getContext("2d");
        this.onCanvasResized(this.canvas.clientWidth, this.canvas.clientHeight);
    }

    //--------------------------------------------------------------------------
    /**
     * Set the widget to the following transformation mode :
     *  * translate
     *  * rotate
     *  * scale
     *  * rectTool
     *
     * @param {String} controlMode Either translate / rotate / scale / rectTool
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setControlMode
     */
    setControlMode(controlMode)
    {
        if(this.currentControlMode == controlMode)
        {
            return;
        }

        this.controller.setMode(controlMode);

        this.releaseController();
        this.currentControlMode = controlMode;
        this.initController();
    }

    //--------------------------------------------------------------------------
    initController()
    {
        // Reinstall listeners of the Input Relay to set the TransformControls event handler in top priority
        this.sdk.streamer.inputRelay.uninstallListeners(true);
        this.sdk.streamer.inputRelay.installListeners(true);

        if(this.currentControlMode == 'rectTool')
        {
            this.mesh.add(this.rectTool.container);
        }
        else
        {
            this.controller.attach(this.mesh);
            this.controller.enabled = true;
        }
    }

    //--------------------------------------------------------------------------
    releaseController()
    {
        if(this.currentControlMode == 'rectTool')
        {
            this.mesh.remove(this.rectTool.container);
            this.rectTool.releaseHandles(this.sdk.domOverlayExt);
        }
        else
        {
            this.controller.detach(this.mesh);
            this.controller.enabled = false;
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Sets the coordinate space in which transformations are applied.
     *
     * @param {String} space Either world / local
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setSpace
     */
    setSpace(space)
    {
        this.currentSpace = space;
        this.controller.setSpace(space);
    }

    //--------------------------------------------------------------------------
    /**
     * Enable a snap in translation control mode, the translation will
     * be rounded with the given value. Set 0 to disable it.
     *
     * @param {Number} value Snapping value
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setTranslationSnap
     */
    setTranslationSnap(value)
    {
        this.controller.setTranslationSnap(value);
        this.rectTool.setTranslationSnap(value);
    }

    //--------------------------------------------------------------------------
    /**
     * Enable a snap in rotation control mode, the rotation will
     * be rounded with the given value in radians. Set 0 to disable it.
     *
     * @param {Number} value Snapping value
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setRotationSnap
     */
    setRotationSnap(value)
    {
        this.controller.setRotationSnap(value);
    }

    //--------------------------------------------------------------------------
    /**
     * Enable a snap in scale control mode, the scale will
     * be rounded with the given value. Set 0 to disable it.
     *
     * @param {Number} value Snapping value
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setScaleSnap
     */
    setScaleSnap(value)
    {
        this.controller.setScaleSnap(value);
        this.rectTool.setScaleSnap(value);
    }

    //--------------------------------------------------------------------------
    /**
     * Show or hide X, Y or Z axis of the translation, rotation and scale controller.
     *
     * @param {boolean} xState Visible state of the X axis
     * @param {boolean} yState Visible state of the Y axis
     * @param {boolean} zState Visible state of the Z axis
     *
     * @method module:SDK3DVerse_Gizmos_Ext#setAxisVisibleState
     */
    setAxisVisibleState(xState, yState, zState)
    {
        this.controller.setAxisVisibleState(xState, yState, zState);
    }

    //--------------------------------------------------------------------------
    /**
     * Enable and show the gizmo
     *
     * @method module:SDK3DVerse_Gizmos_Ext#enable
     */
    enable()
    {
        this.enabled = true;
        this.initController();
    }

    //--------------------------------------------------------------------------
    /**
     * Disable and hide the gizmo
     *
     * @method module:SDK3DVerse_Gizmos_Ext#disable
     */
    disable()
    {
        this.releaseController();
        this.enabled = false;
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the gizmo extension is enabled.
     *
     * @returns {bool} Enable state
     *
     * @method module:SDK3DVerse_Gizmos_Ext#isEnabled
     */
    isEnabled()
    {
        return this.enabled;
    }

    //--------------------------------------------------------------------------
    /**
     * Once enabled, the widget will commit changes to the entity transformed.
     * This will make the change persistent.
     *
     * **Disabled by default**
     *
     * @param {boolean} value Commit changes flag.
     *
     * @method module:SDK3DVerse_Gizmos_Ext#enableSave
     */
    enableSave(value)
    {
        this.commitChanges = value;
    }

    //--------------------------------------------------------------------------
    onEntitySelectionChanged = (selectedEntities) =>
    {
        this.transformableEntities = selectedEntities.filter((e) =>
        {
            return e.isAttached("local_transform")
                && (!e.isExternal() || (e.isComponentOverridden("local_transform") && !e.overrider.isExternal()))
                && (this.sdk.engineAPI.canEdit() || e.isRuntime());
        });

        if(this.transformableEntities.length == 0)
        {
            if(this.isVisible)
            {
                this.releaseController();
                this.onVisibilityChanged(false);
                this.isVisible = false;
            }

            return;
        }

        if(!this.isVisible)
        {
            this.isVisible = true;
            this.onVisibilityChanged(true);
            this.initController();
        }
        this.resetGizmoTransform();
    }

    //--------------------------------------------------------------------------
    onEntitiesUpdated = (entities) =>
    {
        for(const transformableEntities of this.transformableEntities)
        {
            let currentEntity = transformableEntities;
            do
            {
                for(const i in entities)
                {
                    const entity = entities[i];
                    if(currentEntity.getID() == entity.getID())
                    {
                        this.resetGizmoTransform();
                        return;
                    }
                }
            }
            while(currentEntity = currentEntity.getParent());
        }
    }

    //--------------------------------------------------------------------------
    onEntityReparent = (movingEntity) =>
    {
        this.onEntitiesUpdated([movingEntity])
    }

    //--------------------------------------------------------------------------
    resetGizmoTransform()
    {
        // This test should always be false.
        if(this.transformableEntities.length === 0)
        {
            return;
        }

        const globalTransform   = this.transformableEntities[0].getGlobalTransform();
        const localAABB         = this.transformableEntities[0].isAttached('local_aabb')
                                ? this.transformableEntities[0].getComponent('local_aabb')
                                : this.defaultAABB;

        this.mesh.position.fromArray(globalTransform.position);
        this.mesh.quaternion.fromArray(globalTransform.orientation);
        this.mesh.scale.fromArray(globalTransform.scale);
        this.mesh.updateMatrixWorld();

        this.rectTool.setAABB(localAABB);

        this.currentTransform = {
            position : this.mesh.position.clone(),
            scale : this.mesh.scale.clone(),
            rotationAngle : 0
        };
    }

    //--------------------------------------------------------------------------
    onGizmoPositionUpdated = () =>
    {
        // This test should always be false...
        if(!this.currentTransform)
        {
            return;
        }

        let offset = new THREE.Vector3();
        offset.subVectors(this.mesh.position, this.currentTransform.position);
        offset = vec3.fromValues(offset.x, offset.y, offset.z);

        for(const entity of this.transformableEntities)
        {
            const globalPosition = vec3.fromValues(...entity.getGlobalTransform().position);
            vec3.add(globalPosition, globalPosition, offset);

            entity.setPosition(Array.from(globalPosition));
        }

        this.currentTransform.position = this.mesh.position.clone();
    }

    //--------------------------------------------------------------------------
    onGizmoOrientationUpdated = (rotationAxis, rotationAngle) =>
    {
        // This test should always be false...
        if(!this.currentTransform)
        {
            return;
        }

        const offset = rotationAngle - this.currentTransform.rotationAngle;

        for(const entity of this.transformableEntities)
        {
            const localRotationAxis = new THREE.Vector4(rotationAxis.x, rotationAxis.y, rotationAxis.z, 0);

            if(this.currentSpace === 'world')
            {
                const globalMatrix          = new THREE.Matrix4().fromArray(entity.getGlobalMatrix());
                const invertedGlobalMatrix  = new THREE.Matrix4().getInverse(globalMatrix);
                localRotationAxis.applyMatrix4(invertedGlobalMatrix);
            }

            localRotationAxis.normalize();

            const orientationOffset = new THREE.Quaternion().setFromAxisAngle(
                localRotationAxis,
                offset
            );

            orientationOffset.normalize();

            const localOrientation = new THREE.Quaternion().fromArray(
                entity.getComponent('local_transform').orientation
            );

            localOrientation.multiply(orientationOffset);

            entity.setComponent(
                "local_transform",
                {
                    orientation : localOrientation.toArray()
                }
            );
        }

        this.currentTransform.rotationAngle = rotationAngle;
    }

    //--------------------------------------------------------------------------
    onGizmoScaleUpdated = () =>
    {
        // This test should always be false...
        if(!this.currentTransform)
        {
            return;
        }

        for(const entity of this.transformableEntities)
        {
            entity.setScale(this.mesh.scale.toArray());
        }
    }

    //--------------------------------------------------------------------------
    onGizmoTransformEnded()
    {
        if(this.commitChanges)
        {
            this.sdk.engineAPI.commitChanges();
        }

        this.resetGizmoTransform();
    }

    //--------------------------------------------------------------------------
    updateClientRect = () =>
    {
        if(this.viewport)
        {
            const areaSize  = this.viewport.getAreaSize();
            const offset    = this.viewport.getOffset();

            const bounding  = {
                width   : areaSize[0],
                height  : areaSize[1],
                left    : this.cameraAPI.canvasClientRect.left + offset[0],
                top     : this.cameraAPI.canvasClientRect.top  + offset[1],
            };

            this.controller.setBoundingRect(bounding);
            this.rectTool.setBoundingRect(bounding);
        }
        else
        {
            this.controller.setBoundingRect(this.cameraAPI.canvasClientRect);
            this.rectTool.setBoundingRect(this.cameraAPI.canvasClientRect);
        }
    };

    //--------------------------------------------------------------------------
    onViewportSelected = (selectedViewport) =>
    {
        this.viewport = selectedViewport;
        this.updateClientRect();
        this.onFrameRendered();
    }

    //--------------------------------------------------------------------------
    onFrameRendered = () =>
    {
        if(!this.enabled || !this.isVisible)
        {
            return;
        }

        const viewport  = this.viewport;
        if(!viewport)
        {
            return;
        }

        const renderSize = this.renderer.getSize();
        if(renderSize.width == 0 || renderSize.height == 0)
        {
            return;
        }

        const viewportPosition = viewport.getTransform().position;

        if(    this.mesh.position.x == viewportPosition[0]
            && this.mesh.position.y == viewportPosition[1]
            && this.mesh.position.z == viewportPosition[2])
        {
            // Avoid THREE.Matrix4.getInverse() errors.
            return;
        }

        this.camera.matrix.fromArray(viewport.worldMatrix);
        this.camera.updateMatrixWorld(true);
        this.camera.projectionMatrix.fromArray(viewport.projectionMatrix);

        if(this.currentControlMode == 'rectTool' && this.sdk.domOverlayExt)
        {
            this.rectTool.onCameraUpdated(this.camera);
            this.rectTool.updateHandles(this.sdk.domOverlayExt);
        }

        const offset    = viewport.getOffset();
        const areaSize  = viewport.getAreaSize();

        this.renderer.clear();
        this.renderer.setViewport(offset[0], offset[1], areaSize[0], areaSize[1]);
        this.renderer.render(this.scene, this.camera);

        this.context.drawImage(this.overlayCanvas, 0, 0);
    }

    //--------------------------------------------------------------------------
    onCanvasResized = (width, height) =>
    {
        this.renderer.setSize(width, height);
        this.updateClientRect();
    }

    //--------------------------------------------------------------------------
    duplicateEntities(entities)
    {
        return Promise.all(entities.map(entity =>
        {
            let parentEntity = entity.getParent();

            while(parentEntity && parentEntity.isExternal())
            {
                parentEntity = parentEntity.getParent();
            }

            const ordinal   = entity.isAttached('lineage')
                            ? entity.getComponent('lineage').ordinal
                            : 0;

            return SDK3DVerse.engineAPI.createEntity(
                parentEntity,
                entity.getComponents(),
                ordinal + 1
            );
        }));
    }
}

export default SDK3DVerse_Gizmos_Ext;
