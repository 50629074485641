//------------------------------------------------------------------------------
import * as THREE from 'three';

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class RectTool
{
    //--------------------------------------------------------------------------
    constructor(camera, domElement, sdk)
    {
        this.camera         = camera;
        this.domElement     = domElement;
        this.sdk            = sdk;

        this.container      = new THREE.Object3D();
        this.raycaster      = new THREE.Raycaster();

        this.handles        = [
            {domElement : null, localPosition : new THREE.Vector3(), worldPosition : new THREE.Vector3(), dirty : true},
            {domElement : null, localPosition : new THREE.Vector3(), worldPosition : new THREE.Vector3(), dirty : true},
            {domElement : null, localPosition : new THREE.Vector3(), worldPosition : new THREE.Vector3(), dirty : true},
            {domElement : null, localPosition : new THREE.Vector3(), worldPosition : new THREE.Vector3(), dirty : true}
        ];

        this.localAABB = {
            min     : new THREE.Vector3(),
            max     : new THREE.Vector3(),
            size    : new THREE.Vector3()
        };

        this.planeHelpers   = [
            {
                multiplier : new THREE.Vector3(1.0, 1.0, 0.0),
                handles : [
                    new THREE.Vector3(-1.0, -1.0, 0.0),
                    new THREE.Vector3(-1.0, 1.0, 0.0),
                    new THREE.Vector3(1.0, 1.0, 0.0),
                    new THREE.Vector3(1.0, -1.0, 0.0),
                ]
            },
            {
                multiplier : new THREE.Vector3(1.0, 0.0, 1.0),
                handles : [
                    new THREE.Vector3(-1.0, 0.0, -1.0),
                    new THREE.Vector3(-1.0, 0.0, 1.0),
                    new THREE.Vector3(1.0, 0.0, 1.0),
                    new THREE.Vector3(1.0, 0.0, -1.0),
                ]
            },
            {
                multiplier : new THREE.Vector3(0.0, 1.0, 1.0),
                handles : [
                    new THREE.Vector3(0.0, -1.0, -1.0),
                    new THREE.Vector3(0.0, -1.0, 1.0),
                    new THREE.Vector3(0.0, 1.0, 1.0),
                    new THREE.Vector3(0.0, 1.0, -1.0),
                ]
            }
        ];

        this.defaultDirection       = new THREE.Vector3(0.0, 0.0, 1.0);
        this.defaultUp              = new THREE.Vector3(0.0, 1.0, 0.0);
        this.defaultRight           = new THREE.Vector3(1.0, 0.0, 0.0);

        this.planeOrientations      = [
            new THREE.Quaternion(),
            new THREE.Quaternion().setFromUnitVectors(this.defaultDirection,this.defaultUp),
            new THREE.Quaternion().setFromUnitVectors(this.defaultDirection, this.defaultRight)
        ];

        this.planes                 = this.planeHelpers.map(
            (planeHelper, index) => this.createPlane(planeHelper.handles, this.planeOrientations[index])
        );

        this.axis                   = [
            this.defaultRight,
            this.defaultUp,
            this.defaultDirection
        ];

        this.cameraDirection        = new THREE.Vector3();
        this.tempVec                = new THREE.Vector3();
        this.tempVec2               = new THREE.Vector3();
        this.tempVec3               = new THREE.Vector3();
        this.localIntersection      = new THREE.Vector3();
        this.invertedParentMatrix   = new THREE.Matrix4();

        this.container.add(...this.planes);
        this.registerCanvasEvents();
    }

    //--------------------------------------------------------------------------
    createPlane(handles, orientation)
    {
        const geometry      = new THREE.PlaneGeometry();
        const material      = new THREE.MeshBasicMaterial(
        {
            transparent         : true,
            opacity             : 0.0,
            side                : THREE.DoubleSide
        });

        const mesh          = new THREE.Mesh(geometry, material);
        mesh.applyQuaternion(orientation);

        const lineMaterial  = new THREE.LineBasicMaterial( {color: 'blue'} );
        const reversedOrientation = orientation.clone().inverse();

        mesh.handleEdges = handles.map((handle, index) =>
        {
            const nextHandleIndex = (index + 1) % handles.length;

            const A = new THREE.Vector3()
                        .copy(handles[index])
                        .multiplyScalar(0.5)
                        .applyQuaternion(reversedOrientation);

            const B = new THREE.Vector3()
                        .copy(handles[nextHandleIndex])
                        .multiplyScalar(0.5)
                        .applyQuaternion(reversedOrientation);

            const geometry  = new THREE.BufferGeometry().setFromPoints([A, B]);
            const line      = new THREE.Line(geometry, lineMaterial);

            const planePosition     = new THREE.Vector3().addVectors(A, B).multiplyScalar(0.5);
            const isHorizontal      = Math.abs(planePosition.y) > 0;

            const handleGeometry    = new THREE.PlaneGeometry(isHorizontal ? 1.0 : 0.1, !isHorizontal ? 1.0 : 0.1);
            const handlePlane       = new THREE.Mesh(handleGeometry, material);
            handlePlane.position.copy(planePosition);

            line.add(handlePlane);
            mesh.add(line);

            return {
                handleIndexes : [index, nextHandleIndex],
                handlePlane
            };
        });

        const infinitePlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(10000, 10000),
            new THREE.MeshBasicMaterial({visible: false, side: THREE.DoubleSide})
        );

        mesh.add(infinitePlane);
        mesh.infinitePlane = infinitePlane;
        return mesh;
    }

    //--------------------------------------------------------------------------
    setAABB(localAABB)
    {
        this.localAABB.min.fromArray(localAABB.min);
        this.localAABB.max.fromArray(localAABB.max);
        this.localAABB.size.subVectors(this.localAABB.max, this.localAABB.min);

        this.container.scale.copy(this.localAABB.size);
        this.container.position.copy(this.container.scale).multiplyScalar(0.5).add(this.localAABB.min);

        this.setHandleToDirty();
    }

    //--------------------------------------------------------------------------
    setHandleToDirty()
    {
        for(const handle of this.handles)
        {
            handle.dirty = true;
        }
    }

    //--------------------------------------------------------------------------
    setTranslationSnap(translationSnap)
    {
		this.translationSnap = translationSnap;
	}

    //--------------------------------------------------------------------------
    setScaleSnap(scaleSnap)
    {
		this.scaleSnap = scaleSnap;
	}

    //--------------------------------------------------------------------------
    setBoundingRect(rect)
    {
        this.boundingRect = rect;
    }

    //--------------------------------------------------------------------------
    updateHandles(domRenderer)
    {
        if(!this.planeHelper)
        {
            return;
        }

        const parent = this.container.parent;

        for(let i = 0; i < this.handles.length; ++i)
        {
            const handle = this.handles[i];

            if(!handle.dirty)
            {
                continue;
            }

            const handleAxis    = this.planeHelper.handles[i];

            const localPosition = handle.localPosition
                                    .copy(handleAxis)
                                    .multiply(this.container.scale)
                                    .multiplyScalar(0.5)
                                    .add(this.container.position);

            const worldPosition = handle.worldPosition
                                    .copy(localPosition)
                                    .applyMatrix4(parent.matrixWorld);

            if(domRenderer && handle.domElement == null)
            {
                handle.domElement = this.createDomElement(
                    domRenderer,
                    this.onClickOnHandle(i)
                );
            }

            if(handle.domElement)
            {
                handle.domElement.position = worldPosition.toArray();
            }

            handle.dirty = false;
        }
    }

    //--------------------------------------------------------------------------
    releaseHandles(domRenderer)
    {
        for(const handle of this.handles)
        {
            if(handle.domElement)
            {
                domRenderer.releaseDomElement(handle.domElement);
                handle.domElement = null;
            }
        }

        this.currentPlane = null;
    }

    //--------------------------------------------------------------------------
    createDomElement(domRenderer, handler)
    {
        let div             = document.createElement('div');
        div.className       = 'gizmo-handle';
        div.onmousedown     = handler;
        div.ontouchstart    = handler;
        div.draggable       = false;

        return domRenderer.createDomElement(div);
    }

    //--------------------------------------------------------------------------
    registerCanvasEvents()
    {
        this.domElement.addEventListener('mousedown', (event) =>
        {
            if(!this.currentPlane)
            {
                return;
            }

            if (event.button !== 0)
            {
                return;
            }

            this.raycaster.setFromCamera(this.getPointer(event), this.camera);
            const handleEdges   = this.currentPlane.handleEdges.map(({handlePlane}) => handlePlane);
            const intersects    = this.raycaster.intersectObjects(handleEdges, false);
            if(intersects.length === 0)
            {
                let [intersect] = this.raycaster.intersectObject(this.currentPlane, false);
                if(!intersect)
                {
                    return;
                }

                const initialPosition       = this.container.parent.position.clone();
                const initialIntersection   = intersect.point.clone();

                this.createMouseMoveHandler(this.onMouseMovePlane, initialPosition, initialIntersection);

                event.stopPropagation();
                event.stopImmediatePropagation();
                return false;
            }

            const { handleIndexes } = this.currentPlane.handleEdges.find(
                ({handlePlane}) => handlePlane === intersects[0].object
            );

            const handleAxis        = new THREE.Vector3()
                                        .addVectors(
                                            this.planeHelper.handles[handleIndexes[0]],
                                            this.planeHelper.handles[handleIndexes[1]]
                                        ).normalize();

            this.createScaleHandler(handleAxis);

            event.stopPropagation();
            event.stopImmediatePropagation();
            return false;
        });
    }

    //--------------------------------------------------------------------------
    createScaleHandler(handleAxis)
    {
        const currentCenter     = this.handles.reduce(
                                    (accumulator, handle) => accumulator.add(handle.worldPosition),
                                    new THREE.Vector3()
                                ).multiplyScalar(0.25);

        const initialScale      = this.container.parent.scale.clone();
        const orientation       = this.container.parent.quaternion;
        const invOrientation    = orientation.clone().inverse();
        const worldPosToCenter  = new THREE.Vector3()
                                    .subVectors(this.container.parent.position, currentCenter)
                                    .applyQuaternion(invOrientation)
                                    .divide(initialScale);

        const oldMin            = new THREE.Vector3().copy(this.localAABB.min)
                                    .multiply(handleAxis)
                                    .multiply(initialScale)
                                    .applyQuaternion(orientation);
        const oldMax            = new THREE.Vector3().copy(this.localAABB.max)
                                    .multiply(handleAxis)
                                    .multiply(initialScale)
                                    .applyQuaternion(orientation);

        const oldMinMaxVector   = new THREE.Vector3().subVectors(oldMax, oldMin);

        this.invertedParentMatrix.getInverse(this.container.parent.matrixWorld);

        this.createMouseMoveHandler(
            this.onMouseMoveHandle,
            initialScale,
            this.invertedParentMatrix,
            handleAxis,
            worldPosToCenter,
            currentCenter,
            oldMinMaxVector,
            orientation
        );
    }

    //--------------------------------------------------------------------------
    onClickOnHandle(draggingHandleIndex)
    {
        return (e, viewport) =>
        {
            e.preventDefault();
            const handleAxis = this.planeHelper.handles[draggingHandleIndex];
            this.createScaleHandler(handleAxis);
            return false;
        };
    }

    //--------------------------------------------------------------------------
    createMouseMoveHandler(handler, ...args)
    {
        this.onTransformStarted();

        const mouseMoveHandler  = (event) =>
        {
            this.raycaster.setFromCamera(this.getPointer(event), this.camera);

            const intersect = this.raycaster.intersectObject(this.currentPlane.infinitePlane, false).pop();
            if(intersect)
            {
                handler(intersect.point, ...args);
            }

            event.stopPropagation();
            event.stopImmediatePropagation();
            return false;
        };

        const mouseUpHandler = () =>
        {
            this.sdk.streamer.inputRelay.installListeners(true);

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('touchmove', mouseMoveHandler);

            document.removeEventListener('mouseup', mouseUpHandler);
            document.removeEventListener('touchend', mouseUpHandler);
            document.removeEventListener('touchcancel', mouseUpHandler);
            document.removeEventListener('touchleave', mouseUpHandler);

            this.setHandleToDirty();
            this.updateHandles();

            this.onTransformEnded();
        };

        document.addEventListener('mousemove', mouseMoveHandler, false);
        document.addEventListener('touchmove', mouseMoveHandler, false);

        document.addEventListener('mouseup', mouseUpHandler, false);
        document.addEventListener('touchend', mouseUpHandler, false);
        document.addEventListener('touchcancel', mouseUpHandler, false);
        document.addEventListener('touchleave', mouseUpHandler, false);

        this.sdk.streamer.inputRelay.uninstallListeners(true);
    }

    //--------------------------------------------------------------------------
    onMouseMovePlane = (intersection, initialPosition, initialIntersection) =>
    {
        const offset    = this.tempVec.subVectors(intersection, initialIntersection);
        const position  = this.tempVec2.addVectors(initialPosition, offset);

        if(this.translationSnap)
        {
            position.x = Math.round( position.x / this.translationSnap ) * this.translationSnap;
            position.y = Math.round( position.y / this.translationSnap ) * this.translationSnap;
            position.z = Math.round( position.z / this.translationSnap ) * this.translationSnap;
        }

        this.propagateChanges(position);
    }

    //--------------------------------------------------------------------------
    onMouseMoveHandle = (intersection, initialScale, invertedParentMatrix, handleAxis, worldPosToCenter, previousCenterWorldPos, oldVec, orientation) =>
    {
        this.localIntersection
            .copy(intersection)
            .applyMatrix4(invertedParentMatrix);

        const newMin    = new THREE.Vector3().copy(this.localAABB.min);
        const newMax    = new THREE.Vector3().copy(this.localAABB.max);

        // Compute the new min and max
        for(let i = 0; i < this.axis.length; i++)
        {
            const currentComponent = handleAxis.getComponent(i);

            if(currentComponent == 0)
            {
                continue;
            }

            const value = this.localIntersection.getComponent(i);

            if(currentComponent > 0)
            {
                newMax.setComponent(i, value);
            }
            else
            {
                newMin.setComponent(i, value);
            }
        }

        const scale = new THREE.Vector3()
                        .subVectors(newMax, newMin)
                        .divide(this.localAABB.size)
                        .multiply(initialScale);

        if(this.scaleSnap)
        {
            if(handleAxis.x != 0)
            {
                scale.x = Math.round( scale.x / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
            }

            if(handleAxis.y != 0)
            {
                scale.y = Math.round( scale.y / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
            }

            if(handleAxis.z != 0)
            {
                scale.z = Math.round( scale.z / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
            }
        }

        if(this.container.parent.scale.equals(scale))
        {
            return;
        }

        const wMin          = new THREE.Vector3().copy(this.localAABB.min)
                                .multiply(handleAxis)
                                .multiply(scale)
                                .applyQuaternion(orientation);
        const wMax          = new THREE.Vector3().copy(this.localAABB.max)
                                .multiply(handleAxis)
                                .multiply(scale)
                                .applyQuaternion(orientation);

        const newVec        = new THREE.Vector3().subVectors(wMax, wMin);
        const offset        = this.tempVec.subVectors(newVec, oldVec).multiplyScalar(0.5);

        const center        = new THREE.Vector3().copy(previousCenterWorldPos).add(offset);
        const scaledVector  = worldPosToCenter.clone()
                                .multiply(scale)
                                .applyQuaternion(orientation);

        const newWorldPos   = center.add(scaledVector);

        this.propagateChanges(newWorldPos, scale);
    }

    //--------------------------------------------------------------------------
    propagateChanges(worldPosition, scale)
    {
        const object = this.container.parent;
        object.position.copy(worldPosition);

        if(scale)
        {
            object.scale.copy(scale);
        }

        object.updateMatrixWorld();

        for(const handle of this.handles)
        {
            this.tempVec3
                .copy(handle.localPosition)
                .applyMatrix4(object.matrixWorld)
                .toArray(handle.domElement.position);
        }

        this.onPositionUpdated();

        if(scale)
        {
            this.onScaleUpdated();
        }
    }

    //--------------------------------------------------------------------------
    onCameraUpdated(camera)
    {
        camera.getWorldDirection(this.cameraDirection);

        const { nearestPlane } = this.planes.reduce(
            (accumulator, plane) =>
            {
                plane.getWorldDirection(this.tempVec);
                const currentDotValue = Math.abs(this.cameraDirection.dot(this.tempVec));

                if(currentDotValue > accumulator.dotValue)
                {
                    accumulator.nearestPlane    = plane,
                    accumulator.dotValue        = currentDotValue;
                }

                return accumulator;
            },
            {
                nearestPlane    : null,
                dotValue        : 0
            }
        );

        for(const plane of this.planes)
        {
            plane.visible = (nearestPlane === plane);
        }
        if(this.currentPlane !== nearestPlane)
        {
            this.currentPlane   = nearestPlane;
            this.setHandleToDirty();
        }
        this.planeHelper    = this.planeHelpers[this.planes.indexOf(nearestPlane)]
    }

    //--------------------------------------------------------------------------
    getPointer(event)
    {
		const pointer = event.changedTouches ? event.changedTouches[ 0 ] : event;

		return {
			x: ( pointer.clientX - this.boundingRect.left ) / this.boundingRect.width * 2 - 1,
			y: - ( pointer.clientY - this.boundingRect.top ) / this.boundingRect.height * 2 + 1,
			button: event.button
		};
	}
}

//------------------------------------------------------------------------------
export default RectTool;
